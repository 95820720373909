@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    height: 0px;
    width: 0px;
}

::-webkit-scrollbar-thumb {
    background: transparent;
}

::-webkit-scrollbar-corner {
    background: transparent;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}